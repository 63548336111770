export default interface Track {
  trackID: number
  trackName: string
  trackShortName: string
  isOpenBook?: boolean
  isOpenQuestions?: boolean
  isActive: boolean
  participantsNumber: number
  headerImageID?: number
  headerDescription?: string
  primeColor?: string
  languageID?: Language
  reviewers: number[]
  reviewerNames?: string[]
}
export enum Language {
  ENG = 1,
  HEB
}
