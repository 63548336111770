import {useEffect, useState} from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import CuiDialogTitle from 'src/components/custom/CuiDialogTitle'
import UploadImgButton from 'src/components/shl/tracks/UploadImgButton'
import config from 'src/config'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'

interface TrackThemeModalProps {
  primeColor?: string
  headerImgId?: number
  openThemeModal: boolean
  loading: boolean
  onSave: (
    primeColor?: string,
    headerImgId?: number,
    isUpdateStatus?: boolean
  ) => number | Promise<number>
  setOpenThemeModal: (value: boolean) => void
}
export default function TrackThemeModal({
  primeColor,
  headerImgId,
  openThemeModal,
  loading,
  setOpenThemeModal,
  onSave
}: TrackThemeModalProps) {
  const [primeColorState, setPrimeColorState] = useState(primeColor)
  const [imageId, setImageId] = useState(headerImgId)
  const imageLink = `${config.apiUrl}/Image/GetImage/${imageId ?? headerImgId}`
  const theme = useTheme()
  const saveTheme = async () => {
    await onSave(primeColorState, imageId, true)
    setOpenThemeModal(false)
  }

  useEffect(() => setPrimeColorState(primeColor), [primeColor])
  useEffect(() => setImageId(headerImgId), [headerImgId])

  return (
    <Dialog open={openThemeModal} fullWidth>
      <CuiDialogTitle
        title={'Track Theme Settings'}
        onCancelClick={() => setOpenThemeModal(false)}
      />
      <DialogContent>
        <Stack gap={3}>
          <TextField
            type="color"
            label="Prime Color"
            value={primeColorState ?? '#ffffff'}
            onChange={e => setPrimeColorState(e.target.value)}
            sx={{mt: 2}}
          />
          <Stack
            width="100%"
            minHeight={100}
            alignItems="center"
            justifyContent="center"
            borderRadius={2}
            border={0.7}
            borderColor={theme.palette.grey[300]}
          >
            {imageId ? (
              <img src={imageLink} alt={`${imageId}`} style={{maxWidth: 500}} />
            ) : (
              <Typography
                variant="subtitle2"
                textAlign="center"
                color={theme.palette.grey[500]}
              >
                header Image
              </Typography>
            )}
          </Stack>
          <UploadImgButton
            setHeaderImageForTrack={imageId => setImageId(imageId)}
          />
        </Stack>
        <DialogActions sx={{p: '20px 0 0!important'}}>
          <CuiCancelSaveButton
            onCancel={() => setOpenThemeModal(false)}
            loading={loading}
            typeSaveButton="button"
            disableSave={loading}
            disableCancel={loading}
            onSave={saveTheme}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
