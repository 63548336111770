import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router'
import randomColor from 'randomcolor'
import {useSnackbar} from 'notistack'
import {Box, FormControlLabel, Stack, Switch, Alert} from '@mui/material'
import {getEnumOptionWithKey} from 'src/utils/enumHelper'
import {
  DataGridPro,
  GridCellModes,
  GridColDef,
  GridFilterModel,
  GridLinkOperator,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import CloseIcon from '@mui/icons-material/Close'
import CuiOverflowTooltip from 'src/components/custom/CuiOverflowTooltip'
import EmployeeToSHL, {NeedSeferEnum} from 'src/entities/EmployeeToSHL'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'
import ParticipantsToolbar from 'src/components/shl/participants/ParticipantsToolbar'
import ParticipantModal from 'src/components/shl/participants/modals/ParticipantModal'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import {getLocalDateFromString, fDate, fDateAddDays} from 'src/utils/formatTime'
import {changeFilterData} from 'src/utils/externalFilter'
import Label from 'src/components/Label'
import {
  NeedSeferIcon,
  NeedSeferSelect
} from 'src/components/shl/participants/NeedSefer'
import {
  GridColumnParticipants,
  ParticipantsTypeEnum
} from 'src/components/shl/participants/participantsUtils'
import Employee from 'src/entities/Employee'
import useExaminationTypeList from 'src/hooks/useExaminationTypeList'
import {
  expandedGlobalSettingsColumn,
  globalSettingsColumnWithSort
} from 'src/settings/type'
import EditIconButton from 'src/components/shl/shared/EditIconButton'
import useInitalState from 'src/hooks/useInitalState'
import BabyIcon from 'src/images/babyIcon.svg'
interface ParticipantsProps {
  participantsType: ParticipantsTypeEnum
  deleteEmployeeFromInviteList?: (employeeId: number) => void
  addEmployeeToInviteList?: (employee: Employee) => void
}
export default function ParticipantsPage({
  participantsType,
  deleteEmployeeFromInviteList,
  addEmployeeToInviteList
}: ParticipantsProps) {
  const [employeeToShlList, setEmployeeToShlList] = useState<EmployeeToSHL[]>()
  const [selectedEmployeeToShl, setSelectedEmployeeToShl] =
    useState<EmployeeToSHL>()
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isOpenChangeActiveAlert, setIsOpenChangeActiveAlert] =
    useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    linkOperator: GridLinkOperator.And
  })
  const navigate = useNavigate()
  const apiRef = useGridApiRef()
  const {levelId, type} = useParams()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const {examinationTypeList} = useExaminationTypeList()
  const {initialState} = useInitalState(
    participantsType,
    apiRef.current?.exportState,
    apiRef.current.restoreState,
    apiRef.current.setColumnVisibilityModel
  )

  useEffect(() => {
    console.log('Updated state:', selectedEmployeeToShl?.employeeToSHLCycleID)
  }, [selectedEmployeeToShl])

  const columns = useRef<GridColDef[]>([
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnParticipants.Id,
      headerName: 'ID',
      flex: 0.04
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnParticipants.Name,
      headerName: 'Name',
      flex: 0.15,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.lastName} ${params.row.firstName}`,
      renderCell: (params: GridRenderCellParams) => (
        <Box display="flex" width="100%" gap={1}>
          <Box maxWidth={params.row.isNew ? 'calc(100% - 70px)' : '100%'}>
            <CuiOverflowTooltip
              value={`${params.row.lastName} ${params.row.firstName}`}
            />
          </Box>
          {params.row.isML && (
            <img
              style={{
                height: 20,
                width: 20
              }}
              src={BabyIcon}
              alt=""
            />
          )}
          {params.row.isNew && (
            <Label
              color="error"
              variant="ghost"
              sx={{pr: 0.5, ml: 1}}
              endIcon={
                <CloseIcon
                  sx={{cursor: params.row.isActive ? 'pointer' : 'not-allowed'}}
                  onClick={e => {
                    if (!params.row.isActive) return
                    e.stopPropagation()
                    updateEmployeeToShl({...params.row, isNew: false})
                  }}
                />
              }
            >
              New
            </Label>
          )}
        </Box>
      )
    },
    {
      ...expandedGlobalSettingsColumn,
      field: GridColumnParticipants.Track,
      headerName: 'Track',
      align: 'left',
      headerAlign: 'left',
      flex: 0.08,
      valueGetter: (params: GridValueGetterParams) => params.row.trackShortName,
      renderCell: (params: GridRenderCellParams) => (
        <CuiOverflowTooltip
          value={
            params.row.trackShortName && (
              <>
                {params.row.isActive && (
                  <EditIconButton
                    onClick={e => {
                      e.stopPropagation()
                      setIsOpenModal(true)
                      setSelectedEmployeeToShl(params.row)
                    }}
                    disabled={!params.row.isActive}
                    sx={{display: 'none'}}
                  />
                )}
                {params.row.trackShortName}
              </>
            )
          }
        />
      )
    },
    {
      ...expandedGlobalSettingsColumn,
      field: GridColumnParticipants.InvitedDate,
      headerName: 'Invite Date',
      flex: 0.1,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && fDate(params.value)
    },
    {
      ...expandedGlobalSettingsColumn,
      field: GridColumnParticipants.Joindate,
      headerName: 'Join Date',
      flex: 0.1,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && fDate(params.value)
    },
    {
      ...expandedGlobalSettingsColumn,
      field: GridColumnParticipants.ExitDate,
      headerName: 'Exit Date',
      flex: 0.1,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && fDate(params.value)
    },
    {
      ...expandedGlobalSettingsColumn,
      field: GridColumnParticipants.FromTest,
      headerName: 'From Test',
      type: 'number',
      flex: 0.06,
      valueGetter: (params: GridValueGetterParams) =>
        params.value > 0 ? params.value : ''
    },
    {
      ...expandedGlobalSettingsColumn,
      field: GridColumnParticipants.TotalTestsDone,
      headerName: 'Total Tests Done',
      flex: 0.06
    },
    {
      ...expandedGlobalSettingsColumn,
      editable: true,
      field: GridColumnParticipants.NeedSeferID,
      headerName: 'Need Sefer',
      type: 'singleSelect',
      flex: 0.07,
      valueOptions: getEnumOptionWithKey(NeedSeferEnum).map(({key, value}) => ({
        label: value,
        value: key
      })),
      renderCell: (params: GridRenderCellParams) =>
        params.value && <NeedSeferIcon status={params.value} withTooltip />,
      renderEditCell: (params: GridRenderEditCellParams) =>
        params.value && (
          <NeedSeferSelect
            value={params.value}
            onChange={e => {
              updateEmployeeToShl({...params.row, needSeferID: e.target.value})
              apiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value
              })
            }}
          />
        )
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnParticipants.Email,
      headerName: 'Email',
      flex: 0.11,
      renderCell: (params: GridRenderCellParams) => (
        <CuiOverflowTooltip value={params.value} />
      )
    },
    {
      ...expandedGlobalSettingsColumn,
      field: GridColumnParticipants.Phone,
      headerName: 'Phone',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => (
        <CuiOverflowTooltip value={params.value} />
      )
    },
    {
      ...expandedGlobalSettingsColumn,
      field: GridColumnParticipants.Department,
      headerName: 'Department',
      minWidth: 140,
      flex: 0.08
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnParticipants.Office,
      headerName: 'Office',
      flex: 0.04
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnParticipants.City,
      headerName: 'City',
      flex: 0.04,
      renderCell: (params: GridRenderCellParams) => (
        <CuiOverflowTooltip value={params.value} />
      )
    },

    {
      ...globalSettingsColumnWithSort,
      field: GridColumnParticipants.LeaveDate,
      headerName: 'Leave Date',
      flex: 0.1,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && fDate(params.value)
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnParticipants.ReturnDate,
      headerName: 'Return Date',
      flex: 0.1,
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && fDate(params.value)
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnParticipants.MakeUpDueDate,
      headerName: 'Make-up Due Date',
      flex: 0.1,
      type: 'date',
      valueGetter: (params: GridValueGetterParams) =>
        params.row.returnDate && fDateAddDays(params.row.returnDate, 45)
    },
    {
      ...expandedGlobalSettingsColumn,
      field: GridColumnParticipants.IsActive,
      headerName: '',
      flex: 0.05,
      type: 'boolean',
      renderCell: (params: GridRenderCellParams) => (
        <FormControlLabel
          sx={{m: 0}}
          checked={params.value}
          onChange={(e, c) => {
            setSelectedEmployeeToShl({
              ...params.row,
              isActive: c,
              exitDate: c === true ? params.row.exitDate : new Date(),
              joinDate: c === false ? params.row.joinDate : new Date()
            })
            setIsOpenChangeActiveAlert(true)
          }}
          control={<Switch />}
          label=""
        />
      )
    }
  ])

  const getParticipants = useCallback(() => {
    setLoading(true)
    fetchWithUser(
      config.apiUrl +
        `/SHLCycle/Get${ParticipantsTypeEnum[participantsType]}EmployeesByCurrentSHLCycle`
    )
      .then(res => res.json())
      .then(
        (
          data: (EmployeeToSHL & {
            exitDate: string
            joinDate: string
            inviteDate: string
          })[]
        ) => {
          const rows: EmployeeToSHL[] = data.map(et => ({
            ...et,
            joinDate: getLocalDateFromString(et.joinDate),
            exitDate: getLocalDateFromString(et.exitDate),
            inviteDate: getLocalDateFromString(et.inviteDate),
            color: randomColor({luminosity: 'bright'})
          }))
          setEmployeeToShlList(rows)
        }
      )
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again', {
          variant: 'error'
        })
        throw e
      })
  }, [fetchWithUser, enqueueSnackbar, participantsType])

  const initialFilterModel = useCallback(() => {
    if (examinationTypeList.length && employeeToShlList) {
      const levelName =
        examinationTypeList.find(e => e.trackID.toString() === levelId)
          ?.trackShortName || ''
      setFilterModel(p => ({
        ...p,
        items: changeFilterData(
          p.items,
          GridColumnParticipants.Track,
          levelName,
          'contains'
        )
      }))
      setLoading(false)
    }
  }, [examinationTypeList, employeeToShlList, levelId])

  useEffect(initialFilterModel, [initialFilterModel])
  useEffect(getParticipants, [getParticipants])

  const updateEmployeeToShl = (employeeToShl: EmployeeToSHL) => {
    const oldRow = apiRef.current.getRow(employeeToShl.employeeToSHLCycleID)
    apiRef.current.updateRows([employeeToShl])
    const endpointUrl = `/SHLCycle/UpdateEmployeeSHLCycle`
    return fetchWithUser(config.apiUrl + endpointUrl, {
      method: 'POST',
      body: JSON.stringify({...employeeToShl, employee: null}),
      headers: {'Content-Type': 'application/json'}
    })
      .then(() => {
        if (addEmployeeToInviteList && !employeeToShl.isActive)
          addEmployeeToInviteList({
            employeeID: employeeToShl.employeeID,
            firstName: employeeToShl.firstName,
            lastName: employeeToShl.lastName,
            mail: employeeToShl.mail,
            phone: employeeToShl.phone
          } as Employee)
      })
      .catch(e => {
        apiRef.current.updateRows([oldRow])
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }

  const addEmployeeToShl = (employeeToShl: EmployeeToSHL) => {
    const oldRow = apiRef.current.getRow(employeeToShl.employeeToSHLCycleID)
    apiRef.current.updateRows([employeeToShl])
    const endpointUrl = '/SHLCycle/AddEmployeesToSHLCycle'
    return fetchWithUser(config.apiUrl + endpointUrl, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(employeeToShl)
    })
      .then(() => {
        if (deleteEmployeeFromInviteList)
          deleteEmployeeFromInviteList(employeeToShl.employeeID)
      })
      .catch(e => {
        apiRef.current.updateRows([oldRow])
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }

  return (
    <>
      <Stack spacing={3} height="calc(100vh - 120px)">
        <Stack spacing={3} height="100%" mt="8px !important">
          <Box
            sx={{
              boxShadow:
                '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
              borderRadius: 2,
              width: '100%',
              position: 'relative',
              height: '100%',
              mt: 0
            }}
          >
            <DataGridPro
              apiRef={apiRef}
              rows={employeeToShlList ?? []}
              columns={columns.current}
              filterModel={filterModel}
              autoPageSize
              checkboxSelection
              headerHeight={47}
              rowHeight={46}
              density="compact"
              onCellClick={(param, event) => {
                event.defaultMuiPrevented =
                  param.field === GridColumnParticipants.IsActive ||
                  param.field === GridColumnParticipants.NeedSeferID
                if (
                  param.field === GridColumnParticipants.NeedSeferID &&
                  param.row.isActive
                ) {
                  apiRef.current.setCellMode(
                    param.id,
                    param.field,
                    GridCellModes.Edit
                  )
                } else if (
                  param.field !== GridColumnParticipants.IsActive &&
                  type !== 'invited'
                ) {
                  navigate(
                    `/shl/participants/${
                      param.row.isActive ? 'active' : 'reactive'
                    }/user/${param.row.employeeID}/userInfo`
                  )
                }
              }}
              onFilterModelChange={newValue => setFilterModel(newValue)}
              components={{
                Toolbar: ParticipantsToolbar
              }}
              componentsProps={{
                toolbar: {
                  participantsType: participantsType,
                  trackList: examinationTypeList,
                  selectedTrackName:
                    filterModel.items.find(
                      x => x.columnField === GridColumnParticipants.Track
                    )?.value || 0,
                  setFilterModel: setFilterModel,
                  exportState: apiRef.current.exportState,
                  columnVisibilityModel:
                    apiRef.current.state?.columns.columnVisibilityModel
                }
              }}
              getRowId={row => row.employeeToSHLCycleID}
              pageSize={30}
              loading={loading || !initialState}
              isRowSelectable={(params: GridRowParams) => params.row.isActive}
              isCellEditable={params => params.row.isActive}
              getRowClassName={(params: GridRowParams<EmployeeToSHL>) =>
                participantsType === ParticipantsTypeEnum.Active &&
                !params.row.isActive
                  ? 'Reactive'
                  : ''
              }
              sx={{
                '.css-yrdy0g-MuiDataGrid-columnHeaderRow': {
                  background: '#F4F6F8',
                  borderRadius: 1
                },
                '.MuiDataGrid-withBorder': {
                  borderRight: 'none'
                },
                '.MuiDataGrid-footerContainer': {
                  minHeight: 36
                },
                '& .MuiDataGrid-cell:hover': {
                  '& .css-15g97s1-MuiButtonBase-root-MuiIconButton-root': {
                    display: 'inline-block'
                  },
                  '& .css-l92jd1': {
                    display: 'inline-block'
                  }
                },
                '& .Reactive': {
                  opacity: '0.4'
                }
              }}
            />
          </Box>
        </Stack>
      </Stack>
      <ParticipantModal
        employeeToShl={selectedEmployeeToShl}
        setEmployeeToShl={setSelectedEmployeeToShl}
        isOpenModal={isOpenModal}
        onCancelClick={() => setIsOpenModal(false)}
        onAfterSave={(employeeToSHLCycleId?: number) => {
          setIsOpenModal(false)
          apiRef.current.updateRows([
            {
              ...selectedEmployeeToShl!,
              employeeToSHLCycleId: employeeToSHLCycleId
            }
          ])
        }}
        levelList={examinationTypeList}
      />
      <CuiAlertDialog
        fullWidth
        color="error"
        open={isOpenChangeActiveAlert}
        close={() => {
          setIsOpenChangeActiveAlert(false)
        }}
        title={
          selectedEmployeeToShl?.isActive
            ? 'Activate Participant '
            : 'Deactivate Participant '
        }
        body={
          selectedEmployeeToShl?.isActive ? (
            <>
              Participant will be re-added to the active list and will resume
              <br />
              receiving content related to the original registered track.
            </>
          ) : (
            <Alert severity="warning">
              Participant will be removed from active list.
            </Alert>
          )
        }
        okButtonText={
          selectedEmployeeToShl?.isActive ? 'Activate ' : 'Deactivate'
        }
        okClick={async () => {
          if (selectedEmployeeToShl!.isActive) {
            await addEmployeeToShl(selectedEmployeeToShl!)
              .then(() => {
                setIsOpenChangeActiveAlert(false)
              })
              .catch(() => {})
          } else {
            await updateEmployeeToShl(selectedEmployeeToShl!)
              .then(() => {
                setIsOpenChangeActiveAlert(false)
              })
              .catch(() => {})
          }
        }}
      />
    </>
  )
}
