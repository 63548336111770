import {useCallback, useEffect, useState} from 'react'
import '@madisoncres/cb-form-creator/dist/index.css'
import {TestCreator} from '@madisoncres/cb-form-creator'
import {useParams} from 'react-router'
import {useSnackbar} from 'notistack'
import config from 'src/config'
import {useAuth} from 'src/contexts/Auth'
import Examination from 'src/entities/Examination'
import {CircularProgress} from '@mui/material'

export default function TestCreatorPage() {
  const [examination, setExamination] = useState<Examination>()
  const {enqueueSnackbar} = useSnackbar()
  const {examinationId} = useParams()
  const {fetchWithUser} = useAuth()

  const getExaminationById = useCallback(
    (examinationId: number) => {
      fetchWithUser(
        `${config.apiUrl}/Examination/GetExaminationById/${examinationId}`
      )
        .then(res => res.json())
        .then((examination: Examination) => {
          setExamination(examination)
        })
        .catch(() =>
          enqueueSnackbar('Internal server error,please try again', {
            variant: 'error'
          })
        )
    },
    [enqueueSnackbar, fetchWithUser]
  )

  const addFormToExamination = async (
    formId: number,
    totalQuestions: number
  ) => {
    const examinationWithForm = {
      ...examination,
      formID: formId,
      baseExamination: {...examination?.baseExamination, formID: formId},
      totalQuestions: totalQuestions
    }
    return fetchWithUser(`${config.apiUrl}/Examination/UpdateExamination`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(examinationWithForm)
    })
      .then(res => res.json())
      .then(() =>
        enqueueSnackbar(
          `Your form was successfully linked 
                    to examination ${examination?.examinationOrder.order}
                    of track  ${examination?.baseExamination.track?.trackName}`,
          {
            variant: 'success'
          }
        )
      )
      .catch(() =>
        enqueueSnackbar(
          'Internal server error while link your form to wanted examination',
          {
            variant: 'error'
          }
        )
      )
  }

  useEffect(() => {
    if (examinationId) getExaminationById(+examinationId)
    else
      enqueueSnackbar(
        'It is not specified which test you would like to create a quiz for.',
        {variant: 'error'}
      )
  }, [getExaminationById, examinationId, enqueueSnackbar])

  return !examination ? (
    <CircularProgress
      size="3rem"
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%'
      }}
    />
  ) : (
    <TestCreator
      examinationOrder={examination?.examinationOrder.order}
      track={examination?.baseExamination.track}
      form={examination?.form}
      onAfterSaveForm={addFormToExamination}
    />
  )
}
