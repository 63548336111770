import React, {FormEvent, useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import {GridRowModelUpdate} from '@mui/x-data-grid-pro'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  FormControlLabel,
  Switch
} from '@mui/material'
import {useAuth} from 'src/contexts/Auth'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'
import config from 'src/config'
import {ExaminationOrder} from 'src/entities/ExaminationOrder'
import CuiDialogTitle from 'src/components/custom/CuiDialogTitle'
import DateTimePickerModal from 'src/components/shl/shared/DateTimePickerModal'
interface TestModalProps {
  onCancel: () => void
  isOpen: boolean
  test: ExaminationOrder
  setTestList: (updates: GridRowModelUpdate[]) => void
}

export default function TestModal({
  onCancel,
  isOpen,
  test,
  setTestList
}: TestModalProps) {
  const [testForm, setTestForm] = useState<ExaminationOrder>(
    {} as ExaminationOrder
  )
  const [loading, setLoading] = useState<boolean>()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(() => {
    setTestForm(test)
  }, [test])

  const submitForm = async (form: FormEvent<HTMLFormElement>) => {
    form.preventDefault()
    setLoading(true)
    const body: ExaminationOrder = {...testForm}
    fetchWithUser(`${config.apiUrl}/Examination/UpdateExaminationOrder`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        accept: 'text/plain'
      }
    })
      .then(() => {
        enqueueSnackbar('The data saved success')
        setLoading(false)
        onCancel()
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again', {
          variant: 'error'
        })
        setLoading(false)
        throw e
      })
    setTestList([testForm])
  }

  return (
    <Dialog open={isOpen} fullWidth>
      <CuiDialogTitle
        title={`Update Test ${test.order} `}
        onCancelClick={onCancel}
      />
      <DialogContent>
        <Stack mt={1}>
          <form onSubmit={submitForm}>
            <Grid container height={'medium'} rowSpacing={3}>
              <DateTimePickerModal
                label={'Published Time'}
                value={testForm.publishedTime}
                propertyName={'publishedTime'}
                setTestForm={setTestForm}
              />
              <DateTimePickerModal
                label={'Reminder 1'}
                value={testForm.reminder1}
                propertyName={'reminder1'}
                setTestForm={setTestForm}
              />
              <DateTimePickerModal
                label={'Reminder 2'}
                value={testForm.reminder2}
                propertyName={'reminder2'}
                setTestForm={setTestForm}
              />

              <DateTimePickerModal
                label={'Submission Time'}
                value={testForm.submissionTime}
                propertyName={'submissionTime'}
                setTestForm={setTestForm}
              />

              <Grid item xs={12}>
                <FormControlLabel
                  sx={{m: 0}}
                  checked={testForm.isLock}
                  onChange={() =>
                    setTestForm(prev => ({
                      ...prev,
                      isLock: !testForm.isLock
                    }))
                  }
                  control={<Switch />}
                  label="lock/unlock"
                />
              </Grid>
            </Grid>

            <DialogActions sx={{padding: '24px 0 0 0!important', marginTop: 5}}>
              <CuiCancelSaveButton
                onCancel={onCancel}
                loading={loading}
                typeSaveButton="submit"
                disableSave={loading}
                disableCancel={loading}
              />
            </DialogActions>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
