import React, {FormEvent, useState} from 'react'
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack
} from '@mui/material'
import {useSnackbar} from 'notistack'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'
import EmployeeToSHL from 'src/entities/EmployeeToSHL'
import Track from 'src/entities/Track'
import CuiDialogTitle from 'src/components/custom/CuiDialogTitle'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'

interface ParticipantModalProps {
  employeeToShl?: EmployeeToSHL
  setEmployeeToShl: React.Dispatch<
    React.SetStateAction<EmployeeToSHL | undefined>
  >
  isOpenModal: boolean
  onCancelClick: () => void
  onAfterSave: (employeeToSHLCycleId?: number) => void
  levelList: Track[]
}

export default function ParticipantModal({
  employeeToShl,
  setEmployeeToShl,
  isOpenModal,
  onCancelClick,
  onAfterSave,
  levelList
}: ParticipantModalProps) {
  const [loading, setLoading] = useState<boolean>()
  const [selectedValue, setSelectedValue] = useState(employeeToShl)
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  if (!employeeToShl) {
    return <></>
  }

  const updateTrackToEmployee = () => {
    const endpointUrl = '/SHLCycle/UpdateTrackToEmployee'
    return fetchWithUser(config.apiUrl + endpointUrl, {
      method: 'POST',
      body: JSON.stringify(selectedValue),
      headers: {'Content-Type': 'application/json'}
    })
      .then(res => res.json())
      .then((employeeToSHLCycleId: number) => {
        setEmployeeToShl(prev =>
          prev
            ? {
                ...prev,
                employeeToSHLCycleID: employeeToSHLCycleId
              }
            : undefined
        )
        return employeeToSHLCycleId
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }

  const submitForm = async (form: FormEvent<HTMLFormElement>) => {
    form.preventDefault()
    setLoading(true)
    const newId = await updateTrackToEmployee()
    onAfterSave(newId)
    setLoading(false)
  }

  return (
    <Dialog open={isOpenModal} fullWidth>
      <CuiDialogTitle title="Edit participant" onCancelClick={onCancelClick} />
      <DialogContent>
        <Stack spacing={3} mt={1}>
          <form onSubmit={submitForm}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Box
                  paddingTop={1.75}
                  paddingBottom={3}
                  component={'span'}
                  sx={{display: 'flex', alignItems: 'center'}}
                >
                  <Avatar
                    sx={{
                      fontSize: '0.875rem',
                      mr: 1,
                      backgroundColor: employeeToShl.color,
                      color: '#fff'
                    }}
                    component={'span'}
                  >
                    {employeeToShl.firstName.charAt(0)}
                    {employeeToShl.lastName.charAt(0)}
                  </Avatar>
                  {`${employeeToShl.firstName} ${employeeToShl.lastName}`}
                </Box>
              </Grid>
              <Grid item xs={12} pb={1}>
                <FormControl fullWidth>
                  <InputLabel id="track-select-label">Track</InputLabel>
                  <Select
                    required
                    labelId="track-select-label"
                    id="track-select"
                    value={selectedValue?.trackID || employeeToShl.trackID}
                    label="Track"
                    onChange={({target}) => {
                      const selectedLevel = levelList.find(
                        e => e.trackID === +target.value
                      )
                      if (!selectedLevel) return
                      setEmployeeToShl(prev =>
                        prev
                          ? {
                              ...prev,
                              trackID: +target.value,
                              track: selectedLevel,
                              trackShortName: selectedLevel.trackShortName
                            }
                          : undefined
                      )

                      setSelectedValue({
                        ...employeeToShl,
                        trackID: +target.value,
                        track: selectedLevel,
                        trackName: selectedLevel.trackShortName
                      })
                    }}
                    input={<OutlinedInput label="Track" />}
                    sx={{
                      '#track-select': {
                        display: 'flex',
                        alignItems: 'center',
                        py: 1,
                        minHeight: 40
                      }
                    }}
                  >
                    {levelList?.map(r => (
                      <MenuItem
                        key={r.trackID}
                        value={r.trackID}
                        sx={{display: 'flex'}}
                      >
                        <ListItemText primary={r.trackShortName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <DialogActions sx={{padding: '24px 0 0 0!important'}}>
              <CuiCancelSaveButton
                onCancel={onCancelClick}
                loading={loading}
                typeSaveButton="submit"
              />
            </DialogActions>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}