import {Navigate, useRoutes} from 'react-router-dom'
import LoginPage from 'src/components/auth/LoginPage'
import Logout from 'src/components/auth/Logout'
import ProtectedRoute from 'src/components/auth/ProtectedRoutes'
import DashboardLayout from 'src/components/navigation'
import CalendarPage from 'src/components/shl/calander/CalendarPage'
import DashboardPage from 'src/components/shl/DashboardPage'
import TrackList from 'src/components/shl/tracks/TrackList'
import ParticipantsTabs from 'src/components/shl/participants/ParticipantsTabs'
import RafflesPage from 'src/components/shl/raffles/RafflesPage'
import SiyumPage from 'src/components/shl/SiyumPage'
import TestList from 'src/components/shl/tests/TestList'
import TestPage from 'src/components/shl/tests/TestPage'
import RaffleParticipantsPage from 'src/components/shl/raffles/RaffleParticipantsPage'
import UserPage from 'src/components/shl/participants/userProfil/UserPage'
import TestCreatorPage from 'src/components/shl/tests/testTracks/TestCreatorPage'

export default function Router() {
  return useRoutes([
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '/logout',
      element: <Logout />
    },
    {
      path: '/',
      element: <Navigate to="/shl/dashboard" replace />
    },
    {
      path: '/shl',
      element: <DashboardLayout />,
      children: [
        {
          path: 'dashboard',
          element: (
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          )
        },
        {
          path: 'calendar',
          element: (
            <ProtectedRoute>
              <CalendarPage />
            </ProtectedRoute>
          )
        },
        {
          path: 'tracks',
          element: (
            <ProtectedRoute>
              <TrackList />
            </ProtectedRoute>
          )
        },
        {
          path: 'participants',
          children: [
            {
              path: '',
              element: <Navigate to="/shl/participants/active" replace />
            },
            {
              path: ':type',
              children: [
                {
                  path: 'user/:userId/*',
                  element: (
                    <ProtectedRoute>
                      <UserPage />
                    </ProtectedRoute>
                  )
                },
                {
                  path: ':levelId',
                  element: (
                    <ProtectedRoute>
                      <ParticipantsTabs />
                    </ProtectedRoute>
                  )
                },
                {
                  path: '',
                  element: (
                    <ProtectedRoute>
                      <ParticipantsTabs />
                    </ProtectedRoute>
                  )
                }
              ]
            }
          ]
        },
        {
          path: 'tests',
          children: [
            {
              path: '',
              element: (
                <ProtectedRoute>
                  <TestList />
                </ProtectedRoute>
              )
            },
            {
              path: ':id/tracks/:examinationId',
              element: (
                <ProtectedRoute>
                  <TestCreatorPage />
                </ProtectedRoute>
              )
            },
            {
              path: ':id/*',
              element: (
                <ProtectedRoute>
                  <TestPage />
                </ProtectedRoute>
              )
            }
          ]
        },
        {
          path: 'raffles',

          children: [
            {
              path: '',
              element: (
                <ProtectedRoute>
                  <RafflesPage />
                </ProtectedRoute>
              )
            },
            {
              path: ':id/:type',
              element: (
                <ProtectedRoute>
                  <RaffleParticipantsPage />
                </ProtectedRoute>
              )
            }
          ]
        },
        {
          path: 'siyum',
          element: (
            <ProtectedRoute>
              <SiyumPage />
            </ProtectedRoute>
          )
        }
      ]
    }
  ])
}
