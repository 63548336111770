import {ChangeEvent, useState} from 'react'
import {Button, CircularProgress, styled} from '@mui/material'
import {useSnackbar} from 'notistack'
import config from 'src/config'
import {useAuth} from 'src/contexts/Auth'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

const UploadImgButton = ({
  setHeaderImageForTrack
}: {
  setHeaderImageForTrack: (value: number) => void
}) => {
  const [loadingImg, setLoadingImg] = useState(false)
  const {enqueueSnackbar} = useSnackbar()
  const {fetchWithUser} = useAuth()

  const onUploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedImage = event.target.files
    if (!uploadedImage?.length) return
    setLoadingImg(true)
    const formData = new FormData()
    formData.append('image', uploadedImage[0])
    fetchWithUser(`${config.apiUrl}/Image/Upload`, {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then((imageId: number) => setHeaderImageForTrack(imageId))
      .then(() => setLoadingImg(false))
      .catch(() => {
        enqueueSnackbar('An error occurred while uploading this image', {
          variant: 'error'
        })
        setLoadingImg(false)
      })
  }

  return (
    <Button
      component="label"
      variant="contained"
      size="small"
      sx={{mt: 1}}
      disabled={loadingImg}
    >
      {loadingImg && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -1.5,
            marginLeft: -1.5
          }}
        />
      )}
      Upload Header Image
      <VisuallyHiddenInput
        type="file"
        accept="image/*"
        onChange={onUploadImage}
      />
    </Button>
  )
}
export default UploadImgButton
