import React, {FormEvent, useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import {GridRowModelUpdate} from '@mui/x-data-grid-pro'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField
} from '@mui/material'
import {useAuth} from 'src/contexts/Auth'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'
import config from 'src/config'
import Examination from 'src/entities/Examination'
import CuiDialogTitle from 'src/components/custom/CuiDialogTitle'

interface TestTrackModalProps {
  onCancelClick: () => void
  isOpen: boolean
  testTrack: Examination
  setTestTrackList: (updates: GridRowModelUpdate[]) => void
}

export default function TestTrackModal({
  onCancelClick,
  isOpen,
  testTrack,
  setTestTrackList
}: TestTrackModalProps) {
  const [testTrackForm, setTestTrackForm] = useState<Examination>(
    {} as Examination
  )
  const [loading, setLoading] = useState<boolean>()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(() => {
    setTestTrackForm(testTrack)
  }, [testTrack])

  const submitForm = async (form: FormEvent<HTMLFormElement>) => {
    form.preventDefault()
    testTrackForm.baseExamination.examinationUrl = testTrackForm.examinationUrl
    testTrackForm.baseExamination.totalQuestions = testTrackForm.totalQuestions
    setLoading(true)
    const body: Examination = {...testTrackForm}
    if (!body.baseExamination.examinationUrl) {
      body.baseExamination.examinationUrl = body.examinationUrl
      body.baseExamination.totalQuestions = body.totalQuestions
      body.examinationUrl = undefined
      body.totalQuestions = undefined
    }
    fetchWithUser(config.apiUrl + '/Examination/UpdateExamination', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        accept: 'text/plain'
      }
    })
      .then(res => res.json())
      .then(() => {
        setTestTrackList([testTrackForm])
        enqueueSnackbar('The data saved success')
        setLoading(false)
        onCancelClick()
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again', {
          variant: 'error'
        })
        setLoading(false)
        throw e
      })
  }

  return (
    <Dialog open={isOpen} fullWidth>
      <CuiDialogTitle
        title={`Update Track ${testTrack.baseExamination?.track?.trackShortName} in Test ${testTrack.baseExamination?.examinationOrder}`}
        onCancelClick={onCancelClick}
      />
      <DialogContent>
        <Stack mt={1}>
          <form onSubmit={submitForm}>
            <Grid container height={200} rowSpacing={3} columnSpacing={3}>
              <Grid item xs={12}>
                <TextField
                  name="testUrl"
                  label="Test URL"
                  fullWidth
                  value={testTrackForm.examinationUrl ?? ''}
                  onChange={({target}) =>
                    setTestTrackForm(prev => ({
                      ...prev,
                      examinationUrl: target.value
                    }))
                  }
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="totalQuestions"
                  label="Total Questions"
                  fullWidth
                  required
                  value={
                    !!testTrackForm.totalQuestions
                      ? testTrackForm.totalQuestions
                      : ''
                  }
                  InputProps={{inputProps: {min: 0, max: 100}}}
                  onChange={({target}) => {
                    setTestTrackForm(prev => ({
                      ...prev,
                      totalQuestions: +target.value
                    }))
                  }}
                />
              </Grid>
            </Grid>
            <DialogActions sx={{padding: '24px 0 0 0!important', marginTop: 5}}>
              <CuiCancelSaveButton
                onCancel={onCancelClick}
                loading={loading}
                typeSaveButton="submit"
                disableSave={loading}
                disableCancel={loading}
              />
            </DialogActions>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
