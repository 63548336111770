import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router'
import {useSnackbar} from 'notistack'
import {IconButton, Stack} from '@mui/material'
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridToolbarQuickFilter,
  GridValueGetterParams,
  useGridApiRef,
  gridFilteredSortedRowIdsSelector,
  GridCellParams
} from '@mui/x-data-grid-pro'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import HowToVoteIcon from '@mui/icons-material/HowToVote'
import PostAddIcon from '@mui/icons-material/PostAdd'
import {useAuth} from 'src/contexts/Auth'
import {
  GridColumnTestTracks,
  TestScreenEnum
} from 'src/components/shl/tests/testUtils'
import config from 'src/config'
import Examination from 'src/entities/Examination'
import {
  globalSettingsColumn,
  globalSettingsColumnWithSort
} from 'src/settings/type'
import CuiOverflowTooltip from 'src/components/custom/CuiOverflowTooltip'
import PeopleAmountBox from 'src/components/shl/shared/PeopleAmountBox'
import EditIconButton from 'src/components/shl/shared/EditIconButton'
import TestTrackModal from 'src/components/shl/tests/testTracks/TestTrackModal'
import CuiToolbar from 'src/components/custom/CuiToolbar'
import useInitalState from 'src/hooks/useInitalState'
import DataGridTotalRows from 'src/components/shl/shared/DataGridTotalRows'
import {Tooltip} from '@material-ui/core'

interface TestTrackListProps {
  examinationOrder?: number
}

export default function TestTrackList({examinationOrder}: TestTrackListProps) {
  const [testTrackList, setTestTrackList] = useState<Examination[]>([])
  const [selectedRow, setSelectedRow] = useState<Examination>({} as Examination)
  const [loading, setLoading] = useState<boolean>(true)
  const [isOpenModal, setIsOpenModal] = useState<boolean>()
  const apiRef = useGridApiRef()
  const navigate = useNavigate()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const {id} = useParams()
  const {initialState} = useInitalState(
    TestScreenEnum.ExaminationCycleTypes,
    apiRef.current?.exportState,
    apiRef.current.restoreState,
    apiRef.current.setColumnVisibilityModel
  )

  const columns: GridColDef[] = [
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTestTracks.Track,
      headerName: 'Track',
      flex: 0.15,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.baseExamination.track?.trackShortName
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTestTracks.TotalExamineesOnTime,
      headerName: 'Examinees On Time',
      type: 'number',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => (
        <PeopleAmountBox amount={params.value} />
      )
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTestTracks.TotalExamineesLate,
      headerName: 'Examinees Late',
      type: 'number',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => (
        <PeopleAmountBox amount={params.value} />
      )
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTestTracks.TotalNotExaminees,
      headerName: 'Not Examinees',
      type: 'number',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => (
        <PeopleAmountBox amount={params.value} />
      )
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTestTracks.AvgGrade,
      headerName: 'Average Grade',
      type: 'number',
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.totalExamineesOnTime || params.row.totalExamineesLate
          ? 100 - (params.row.totalQuestions ?? 0) + +params.value.toFixed(2)
          : ''
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTestTracks.TotalLotteryTickets,
      headerName: 'Raffle Tickets',
      type: 'number',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => (
        <PeopleAmountBox
          amount={params.value}
          icon={<HowToVoteIcon sx={{mr: 1}} />}
        />
      )
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTestTracks.ExaminationUrl,
      headerName: 'Test URL',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) =>
        params.value && (
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="center"
            whiteSpace="nowrap"
          >
            <CuiOverflowTooltip value={params.value} />
            <IconButton
              onClick={e => {
                e.stopPropagation()
                navigator.clipboard.writeText(params.value)
                enqueueSnackbar('Copied!')
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Stack>
        )
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTestTracks.TotalQuestions,
      headerName: 'Total Points',
      flex: 0.1
    },
    {
      ...globalSettingsColumn,
      field: 'actions',
      headerName: '',
      flex: 0.08,
      align: 'right',
      disableExport: true,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row">
          <Tooltip title="create a test for this examination">
            <IconButton
              onClick={() =>
                navigate(`/shl/tests/${id}/tracks/${params.row.examinationID}`)
              }
            >
              <PostAddIcon />
            </IconButton>
          </Tooltip>
          <EditIconButton
            onClick={e => {
              e.stopPropagation()
              setSelectedRow(params.row)
              setIsOpenModal(true)
            }}
          />
        </Stack>
      )
    }
  ]

  const GetExaminationCycleTypeList = useCallback(() => {
    fetchWithUser(
      config.apiUrl + `/Examination/GetExaminationListByOrderId/${id}`
    )
      .then(res => res.json())
      .then((data: Examination[]) => {
        setTestTrackList(data)
        setLoading(false)
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        setLoading(false)
        throw e
      })
  }, [fetchWithUser, enqueueSnackbar, id])

  useEffect(GetExaminationCycleTypeList, [GetExaminationCycleTypeList])

  const onCellClick = (p: GridCellParams) => {
    switch (p.field) {
      case GridColumnTestTracks.TotalExamineesOnTime: {
        navigate(
          `/shl/tests/${id}/examinees/${p.row.baseExamination.track.trackID}/1`
        )
        return
      }
      case GridColumnTestTracks.TotalExamineesLate: {
        navigate(
          `/shl/tests/${id}/examinees/${p.row.baseExamination.track.trackID}/2`
        )
        return
      }
      case GridColumnTestTracks.TotalNotExaminees: {
        navigate(
          `/shl/tests/${id}/examinees/${p.row.baseExamination.track.trackID}/3`
        )
        return
      }
      case GridColumnTestTracks.Actions:
        return
      default: {
        navigate(
          `/shl/tests/${id}/examinees/${p.row.baseExamination.track.trackID}`
        )
        return
      }
    }
  }

  return (
    <>
      <Stack spacing={3} height="calc(100% - 84px)">
        <DataGridPro
          rows={testTrackList}
          apiRef={apiRef}
          columns={columns}
          experimentalFeatures={{newEditingApi: true}}
          autoPageSize
          disableExtendRowFullWidth
          headerHeight={42}
          rowHeight={47}
          getRowId={row => row.examinationID}
          onCellClick={onCellClick}
          pageSize={30}
          loading={loading || !initialState}
          components={{
            Toolbar: () => (
              <Stack>
                <CuiToolbar
                  screenId={TestScreenEnum.ExaminationCycleTypes}
                  exportState={apiRef.current.exportState}
                  columnVisibilityModel={
                    apiRef.current.state?.columns.columnVisibilityModel
                  }
                  csvFileName={`TestNumber${examinationOrder ?? ''}Tracks`}
                />
                <GridToolbarQuickFilter
                  placeholder="Search test"
                  fullWidth
                  variant="outlined"
                  sx={{
                    mx: 2,
                    my: 1,
                    height: 52,
                    width: 'calc(100% - 48px)',
                    '.MuiFormControl-root': {
                      height: '100%'
                    },
                    '.MuiOutlinedInput-root': {
                      height: '100%'
                    }
                  }}
                />
              </Stack>
            ),
            Footer: () => (
              <DataGridTotalRows
                amount={gridFilteredSortedRowIdsSelector(apiRef).length}
              />
            )
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              debounceMs: 300
            }
          }}
          sx={{
            '.css-yrdy0g-MuiDataGrid-columnHeaderRow': {
              background: '#F4F6F8',
              borderRadius: 1
            },
            '.MuiDataGrid-withBorder': {
              borderRight: 'none'
            }
          }}
        />
      </Stack>
      <TestTrackModal
        onCancelClick={() => setIsOpenModal(false)}
        isOpen={isOpenModal ?? false}
        testTrack={selectedRow}
        setTestTrackList={apiRef.current.updateRows}
      />
    </>
  )
}