import {
  GridColumnVisibilityModel,
  GridColDef,
  GridValueGetterParams,
  GridCellParams,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import {Box} from '@mui/material'
import AlarmOnIcon from '@mui/icons-material/AlarmOn'
import AlarmOffIcon from '@mui/icons-material/AlarmOff'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import {globalSettingsColumnWithSort} from 'src/settings/type'
import {getEnumOptionWithKey} from 'src/utils/enumHelper'
import {SubmissionStatusEnum} from 'src/entities/EmployeeToExamination'
import PeopleAmountBox from 'src/components/shl/shared/PeopleAmountBox'
import LockIcon from '@mui/icons-material/Lock'

export enum GridColumnParticipants {
  Id = 'employeeID',
  Name = 'name',
  Track = 'track',
  Joindate = 'joinDate',
  ExitDate = 'exitDate',
  InvitedDate = 'inviteDate',
  FromTest = 'examinationOrderStart',
  TotalTestsDone = 'totalTestsDone',
  NeedSeferID = 'needSeferID',
  Email = 'mail',
  Phone = 'phone',
  Department = 'departmentName',
  Office = 'officeName',
  City = 'city',
  IsActive = 'isActive',
  LeaveDate = 'mlStartDate',
  ReturnDate = 'returnDate',
  MakeUpDueDate = 'makeUpDueDate'
}

export enum GridColumnParticipantTest {
  Name = 'name',
  Score = 'score',
  Score2 = 'score2',
  SubmissionStatus = 'submissionStatus',
  LotteryTickets = 'lotteryTickets'
}

export enum ParticipantsTypeEnum {
  Active = 1,
  Reactive,
  Invited,
  Ml = 9
}

export type ParticipantsTypeKeys = keyof typeof ParticipantsTypeEnum

export const initialColumnVisibilityDict: {
  [key in ParticipantsTypeEnum]: GridColumnVisibilityModel
} = {
  [ParticipantsTypeEnum.Active]: {
    inviteDate: false,
    exitDate: false,
    leaveDate: false,
    returnDate: false,
    makeUpDueDate: false
  },
  [ParticipantsTypeEnum.Reactive]: {
    inviteDate: false,
    joinDate: false,
    leaveDate: false,
    returnDate: false,
    makeUpDueDate: false
  },
  [ParticipantsTypeEnum.Invited]: {
    track: false,
    joinDate: false,
    exitDate: false,
    examinationOrderStart: false,
    totalTestsDone: false,
    needSeferID: false,
    isActive: false,
    leaveDate: false,
    returnDate: false,
    makeUpDueDate: false
  },
  [ParticipantsTypeEnum.Ml]: {
    track: false,
    joinDate: false,
    exitDate: false,
    inviteDate: false,
    examinationOrderStart: false,
    totalTestsDone: false,
    needSeferID: false,
    mail: false,
    phone: false,
    departmentName: false,
    officeName: false,
    city: false,
    isActive: false
  }
}

export const getFieldToSort = (participantsType: ParticipantsTypeEnum) => {
  switch (participantsType) {
    case ParticipantsTypeEnum.Active:
      return GridColumnParticipants.Joindate
    case ParticipantsTypeEnum.Reactive:
      return GridColumnParticipants.ExitDate
    default:
      return GridColumnParticipants.InvitedDate
  }
}

export const userTestColumns: GridColDef[] = [
  {
    ...globalSettingsColumnWithSort,
    field: GridColumnParticipantTest.Name,
    headerName: 'Test',
    flex: 0.1,
    valueGetter: (params: GridValueGetterParams) =>
      `Test ${params.row.examinationOrder}`,
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.isLock) {
        return (
          <PeopleAmountBox
            amount={
              <>
                {`Test ${params.row.examinationOrder}`}
                <LockIcon fontSize="small" />
              </>
            }
            color={'black'}
            icon={null}
          />
        )
      }
    },
    cellClassName: (params: GridCellParams<number>) =>
      params.row.isLock ? 'isLock' : ''
  },
  {
    ...globalSettingsColumnWithSort,
    field: GridColumnParticipantTest.SubmissionStatus,
    headerName: 'Submission',
    flex: 0.1,
    valueOptions: getEnumOptionWithKey(SubmissionStatusEnum).map(
      ({key, value}) => ({
        label: value,
        value: key
      })
    ),
    renderCell: (params: GridRenderCellParams) => {
      switch (params.value) {
        case 1:
          return (
            <AlarmOnIcon
              sx={{
                color:
                  params.value === SubmissionStatusEnum.OnTime
                    ? 'primary.main'
                    : 'error',
                mr: 3
              }}
            />
          )
        case 2:
          return <AlarmOffIcon sx={{color: 'red', mr: 3}} />
      }
    }
  },
  {
    ...globalSettingsColumnWithSort,
    field: GridColumnParticipantTest.Score,
    headerName: 'Grade',
    flex: 0.1,
    valueGetter: (params: GridValueGetterParams) =>
      100 - (params.row.totalQuestions - params.row.score),
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.loteryTicketsNumber === 2) {
        return (
          <PeopleAmountBox
            amount={
              <>
                {100 - (params.row.totalQuestions - params.row.score)}
                <MilitaryTechIcon />
              </>
            }
            color={'primary.main'}
            icon={null}
          />
        )
      }
    },
    cellClassName: (params: GridCellParams<number>) =>
      params.row.loteryTicketsNumber === 0 ? 'loseTicket' : ''
  },
  {
    ...globalSettingsColumnWithSort,
    field: GridColumnParticipantTest.Score2,
    headerName: 'Points',
    flex: 0.1,
    type: 'string',
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.score} / ${params.row.totalQuestions}`
  },
  {
    ...globalSettingsColumnWithSort,
    field: GridColumnParticipantTest.LotteryTickets,
    headerName: 'Raffle Tickets',
    flex: 0.1,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.loteryTicketsNumber
  }
]

interface DataGridFooterTotalTestsProps {
  totalTestSubmited: number
  onTime: number
  late: number
}

export function DataGridFooterTotalTests({
  totalTestSubmited,
  onTime,
  late
}: DataGridFooterTotalTestsProps) {
  return (
    <Box sx={{textAlign: 'end', my: 1.5, mr: 4}}>
      <span>
        {' '}
        Total submitted: {totalTestSubmited} | On time: {onTime} | Late: {late}
      </span>
    </Box>
  )
}

export enum UserTestEnum {
  Tests = 8
}
