import Employee from 'src/entities/Employee'
import Examination from 'src/entities/Examination'
import {Language} from 'src/entities/Track'

export interface EmployeeToExamination {
  employeeToExaminationID: number
  employeeID: number
  examinationID: number
  examination?: Examination
  submissionTime?: Date
  score?: number
  lotteryTicketsNumber?: number
  isWinnersLottery?: boolean
  isML?: boolean
  employee?: Employee
  submissionStatus: SubmissionStatusEnum
}

export enum SubmissionStatusEnum {
  OnTime = 1,
  Late,
  Lack,
  'on time' = OnTime,
  'late' = Late,
  'lack' = Lack
}

export interface ExaminToEmpType {
  examinationOrder: number
  examinationUrl: string
  examinationCycleLanguageId?: Language
  isLock?: boolean
  isWinnerLotery?: boolean
  loteryTicketsNumber?: number
  submissionStatus?: SubmissionStatusEnum
  score?: number
  totalQuestions?: string
}
